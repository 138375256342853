export const termsTexts = {
  'nexos': {
    customer: 'Nexos',
    headerImage: '/assets/customers/nexos/nexos-auth-header-short.png',
    title: 'Condiciones de la Comunidad Bancolombia',
    lastUpdate: '20 de diciembre de 2024',
    content: `
      <p>
        Encuentra aquí las condiciones y reglas para hacer parte de la Comunidad Nexos de Bancolombia. Este es un espacio abierto donde todas las personas, ya sean clientes o no de Bancolombia, pueden ingresar para informarse, compartir conocimiento, consejos y buenas prácticas sobre educación financiera y finanzas personales, así como conocer más sobre los productos y servicios de Bancolombia.
      </p>
      <p>
        En este lugar, podrás hacer preguntas y responder las dudas de otros participantes. Es un espacio para interactuar tanto con el equipo Bancolombia como con otros usuarios de la Comunidad Nexos.
      </p>
      <h3>¿Quiénes pueden ser parte de la Comunidad Bancolombia?</h3>
      <p>
        Tanto clientes como usuarios, mayores de edad, podrán ser parte de esta Comunidad. No obstante, para poder leer la información publicada e interactuar en la Comunidad Bancolombia, deberán estar registrados, aceptar los presentes términos y condiciones, la autorización de tratamiento de datos personales e iniciar sesión.
      </p>
      <p>
        ¡No te preocupes!, el registro es muy sencillo, solo debes dar clic en la opción "registrarme", escribe tu nombre completo, correo electrónico y crea una contraseña mínima de 8 caracteres alfanuméricos, ten en cuenta no usar los datos de tus productos bancarios. Luego, lee y acepta los términos y condiciones y autoriza el tratamiento de datos personales, recibirás un código de seguridad en la bandeja de entrada de tu correo, sino está allí, revisa las carpetas de correos No deseados o Spam. Una vez activada tu cuenta recibirás un correo de confirmación para que inicies sesión en la Comunidad Nexos.
      </p>
      <p>
        Una vez hayas completado los pasos anteriores, estás listo para generar contenido, crear, comentar o responder publicaciones de otros, y para interactuar con el equipo Bancolombia.
      </p>
      <h3>Reglas de oro</h3>
      <p>
        Para que nuestra Comunicación sea agradable y puedas encontrar información relevante, te pedimos seguir las siguientes reglas de oro al participar en la Comunidad Bancolombia:
      </p>
      <ul>
        <li>Somos una Comunidad incluyente, diversa y equitativa, por eso evita generar contenido que fomente la violencia, odio o que pueda dañar la reputación de otra persona o de Bancolombia.</li>
        <li>Usa siempre un lenguaje respetuoso, en general procura tratar a los demás como te gusta que te traten. No utilices lenguaje ofensivo, agresivo o amenazante en tus interacciones.</li>
        <li>Queremos proteger tu información y la de terceros, por eso evita publicar información personal, privada o confidencial. Recuerda que nunca te pediremos datos financieros como número de productos, claves, usuario.</li>
        <li>No publiques correos electrónicos, teléfonos fijos o celulares, números de productos financieros, tarjetas de crédito, códigos de seguridad, o cualquier dato que pueda ser utilizado por defraudadores. Evita también publicar datos o fotos de otras personas sin su autorización.</li>
        <li>Esta Comunidad es segura, te invitamos a que te registres con tus datos reales, no te hagas pasar por otras personas y no prestes tu usuario y clave para que otros ingresen en tu nombre.</li>
        <li>El objetivo de la comunidad es claro, en este espacio no podrás hacer comentarios sobre afinidades religiosas, políticas, ni promover productos, servicios, marcas de negocios, empresas o páginas web externas.</li>
        <li>En Bancolombia nos tomamos muy en serio la ética e integralidad, por esto tenemos cero tolerancia al fraude, a los sobornos y demás conductas similares.</li>
        <li>Queremos mejorar constantemente, te invitamos a que notifiques al Equipo Bancolombia cualquier error, información falsa, irrespetuosa, ofensiva, que encuentres en nuestra Comunidad.</li>
      </ul>
      <p>
        La Comunidad Nexos no está habilitada para recibir y gestionar peticiones, quejas, reclamos o solicitudes (PQRS), por tanto, no se permitirá publicar interacciones que estén enmarcadas en este tipo de trámites. A pesar de ello, nuestros moderadores te compartirán la información de los canales oficiales que puedes utilizar para radicar y gestionar tus trámites.
      </p>
      <p>
        Ten en cuenta que este espacio no es un canal de servicio al cliente, sino de autogestión. Si necesitas soporte especializado para tus trámites, realizar consultas de tus productos, servicios o resolver inquietudes sobre estos términos y condiciones, así como para cuestiones de privacidad y protección de datos, debes comunicarte con nuestros canales de atención, los encuentras aquí.
      </p>
      <h3>Canales habilitados para PQRS</h3>
      <ul>
        <li>Sucursal telefónica. Medellín +57 (604) 510 9000 - Bogotá +57 (601) 343 0000 - Línea gratuita resto del País: 01 8000 9 12345 - WhatsApp: 3013536788</li>
        <li>Red de oficinas de Bancolombia.</li>
        <li>Redes Sociales (Facebook, Instagram, Tiktok)</li>
        <li>Fuerzas comerciales especializadas.</li>
        <li>Líneas de negocio.</li>
        <li>Defensor del Consumidor Financiero.</li>
        <li>Superintendencia Financiera de Colombia.</li>
        <li>Instancias judiciales definidas normativamente.</li>
      </ul>
      <p>
        Este espacio solo puede ser utilizado para los fines previstos de acuerdo con estos términos y condiciones, el contenido a publicar será revisado antes de su publicación. Si el equipo de Bancolombia identifica que alguna publicación o comentario infringe las reglas de oro, esta será rechazada. Además, cualquier uso indebido, puede resultar en la suspensión o cancelación del acceso del usuario a la Comunidad Nexos.
      </p>
      <h3>¿Qué hacemos con la información que registraste?</h3>
      <p>
        Tu información y datos personales serán tratados de conformidad con las políticas de tratamiento de datos de Bancolombia y, para los fines previstos en la autorización de tratamiento de datos que aceptaste para el registro, los cuales puedes consultar <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">aquí</a>.
      </p>
      <p>
        La información queda registrada en la plataforma de Copernic Technologies SL (Nectios). No obstante, es Bancolombia el responsable de administrar la información allí registrada, de conformidad a la autorización para el tratamiento de datos personales.
      </p>
      <p>
        Tu información y datos están protegidos y seguros en la Comunidad Nexos, pues cuenta con medidas de seguridad, incluyendo cifrado y controles de acceso, para proteger tus datos personales.
      </p>
      <p>
        Ten presente que La Comunidad Nexos no se hace responsable por pérdidas o daños derivados del uso de los servicios, a menos que estos resulten de una negligencia grave o mala conducta intencional.
      </p>
      <p>
        La Comunidad Nexos se proporciona "tal cual", sin garantías de ningún tipo, explícitas o implícitas.
      </p>
      <p>
        La Comunidad Nexos, se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Cualquier cambio será comunicado, y el uso continuo de la Plataforma constituye aceptación de los mismos.
      </p>
      <p>
        Si deseas en algún momento cancelar tu registro como miembro de la Comunidad Nexos de Bancolombia, puedes hacerlo en cualquier momento. La Comunidad, también se reserva el derecho de finalizar la cuenta de cualquier Usuario que incumpla estos términos.
      </p>
    `
  },
  
  // Default terms
  default: {
    customer: 'Default',
    title: 'Terms and Conditions',
    lastUpdate: '1/4/2022',
    content: `
      <p>This is the default terms and conditions. Please customize this content as needed.</p>
      <p>Add your organization's terms here...</p>
    `
  }
} 