export const cookiesTexts = {
    'nexos': {
        customer: 'Nexos',
        headerImage: '/assets/customers/nexos/nexos-auth-header-short.png',
        translations: null,
        lastUpdate: '20 de diciembre de 2024'
    },

    'nectios': {
        customer: 'Nectios',
        headerImage: '/assets/customers/nectios/auth-header-short.png',
        translations: null,
        lastUpdate: null
    },
    
    default: {
        customer: 'Default',
        lastUpdate: '1/4/2022',
        translations: {
            es: {
                title: 'Política de uso de Cookies',
                content: `
                <h3>¿Qué son las cookies?</h3>
                <p>
                    Una cookie es un archivo que se descarga a su computadora cuando usted accede a ciertas páginas web. Las cookies permiten a un sitio web, entre otras cosas, almacenar y recuperar información acerca de los hábitos del navegador de un usuario o de su equipo, y, dependiendo de la información que contienen y la forma en que utilizan su ordenador, pueden ser utilizadas para reconocer al usuario.
                </p>
                <h3>¿Qué tipo de cookies utiliza este sitio web?</h3>
                <ul>
                    <li>
                    <strong>Cookies de Analytics:</strong> Son aquellas que son tratadas por nosotros o por terceros, y nos permiten cuantificar el número de usuarios y realizar la medición y el análisis estadístico de cómo los usuarios utilizan el servicio ofrecido. Examinamos su navegación en nuestro sitio web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
                    </li>
                    <li>
                    <strong>Cookies de publicidad:</strong> Son aquellas que, bien tratadas por nosotros o por terceros, permiten la gestión de la oferta de espacios publicitarios en el sitio web de la manera más eficaz posible, adaptando el contenido publicitario al contenido del servicio solicitado o al uso de la página web que hace el Usuario.
                    </li>
                    <li>
                    <strong>Cookies de preferencias:</strong> Estas cookies permiten al sitio web recordar información que cambia la forma en que el sitio se comporta o se ve, como su idioma preferido o ubicación.
                    </li>
                    <li>
                    <strong>Cookies de seguridad:</strong> Estas cookies permiten al sitio web autenticar a los usuarios, evitar la utilización fraudulenta de las credenciales de inicio de sesión, y proteger los datos de usuario de terceros no autorizados.
                    </li>
                    <li>
                    <strong>Cookies del proceso:</strong> Estas cookies ayudan a hacer que el sitio web funcione y entregue los servicios que el visitante del sitio web espera, como la navegación por páginas web o el acceso a áreas seguras del sitio web.
                    </li>
                    <li>
                    <strong>Cookies específicas:</strong> Estas cookies permiten al sitio web recordar la siguiente información: nombre y número de teléfono.
                    </li>
                </ul>
                <h3>¿Cómo puede desactivar las cookies?</h3>
                <p>
                    Puede permitir, bloquear o eliminar las cookies instaladas en su equipo a través de las opciones de configuración del navegador instalado en su ordenador.
                </p>
                <ul>
                    <li>
                    En Chrome, ver esta página: <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647</a>
                    </li>
                    <li>
                    En Explorer, ver esta página: <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                    </li>
                    <li>
                    En Firefox, ver esta página: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
                    </li>
                    <li>
                    En Safari, ver esta página: <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
                    </li>
                </ul>
                `
            },
            en: {
                title: 'Cookies Usage Policy',
                content: `
                <h3>What are cookies?</h3>
                <p>
                    A cookie is a file that is downloaded to your computer when you access certain web pages. Cookies allow a website, among other things, to store and retrieve information about a user's browser habits or their equipment, and, depending on the information they contain and how they use their computer, they can be used to recognize the user.
                </p>
                <h3>What types of cookies does this website use?</h3>
                <ul>
                    <li>
                    <strong>Analytics Cookies:</strong> These are those that are processed by us or by third parties, and allow us to quantify the number of users and perform statistical measurement and analysis of how users use the service offered. We examine your browsing on our website in order to improve the products or services we offer you.
                    </li>
                    <li>
                    <strong>Advertising Cookies:</strong> These are those that, whether processed by us or by third parties, allow the management of advertising spaces on the website in the most effective way possible, adapting the advertising content to the content of the requested service or to the use of the website made by the User.
                    </li>
                    <li>
                    <strong>Preferences Cookies:</strong> These cookies allow the website to remember information that changes the way the site behaves or looks, such as your preferred language or location.
                    </li>
                    <li>
                    <strong>Security Cookies:</strong> These cookies allow the website to authenticate users, prevent fraudulent use of login credentials, and protect user data from unauthorized third parties.
                    </li>
                    <li>
                    <strong>Process Cookies:</strong> These cookies help make the website work and deliver the services that the website visitor expects, such as navigating web pages or accessing secure areas of the website.
                    </li>
                    <li>
                    <strong>Specific Cookies:</strong> These cookies allow the website to remember the following information: name and phone number.
                    </li>
                </ul>
                <h3>How can you disable cookies?</h3>
                <p>
                    You can allow, block, or delete cookies installed on your computer through the configuration options of the browser installed on your computer.
                </p>
                <ul>
                    <li>
                    For Chrome, see this page: <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647</a>
                    </li>
                    <li>
                    For Explorer, see this page: <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                    </li>
                    <li>
                    For Firefox, see this page: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
                    </li>
                    <li>
                    For Safari, see this page: <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
                    </li>
                </ul>
                `
            },
            ca: {
                title: 'Política d\'ús de Cookies',
                content: `
                <h3>Què són les cookies?</h3>
                <p>
                    Una cookie és un arxiu que es descarrega al vostre ordinador quan accediu a determinades pàgines web. Les cookies permeten a un lloc web, entre altres coses, emmagatzemar i recuperar informació sobre els hàbits de navegació d'un usuari o del seu equip, i, depenent de la informació que contenen i de com utilitzen el seu ordinador, poden ser utilitzades per reconèixer l'usuari.
                </p>
                <h3>Quins tipus de cookies utilitza aquest lloc web?</h3>
                <ul>
                    <li>
                    <strong>Cookies d'Analítica:</strong> Són aquelles que són tractades per nosaltres o per tercers, i ens permeten quantificar el nombre d'usuaris i realitzar el mesurament i l'anàlisi estadística de com els usuaris utilitzen el servei ofert. Examinem la vostra navegació al nostre lloc web per tal de millorar els productes o serveis que us oferim.
                    </li>
                    <li>
                    <strong>Cookies de Publicitat:</strong> Són aquelles que, bé tractades per nosaltres o per tercers, permeten la gestió dels espais publicitaris al lloc web de la manera més eficaç possible, adaptant el contingut publicitari al contingut del servei sol·licitat o a l'ús del lloc web que fa l'Usuari.
                    </li>
                    <li>
                    <strong>Cookies de Preferències:</strong> Aquestes cookies permeten al lloc web recordar informació que canvia la manera com el lloc es comporta o es veu, com ara el vostre idioma preferit o ubicació.
                    </li>
                    <li>
                    <strong>Cookies de Seguretat:</strong> Aquestes cookies permeten al lloc web autenticar els usuaris, evitar l'ús fraudulent de les credencials d'inici de sessió iProtegir les dades d'usuari de tercers no autoritzats.
                    </li>
                    <li>
                    <strong>Cookies de Procés:</strong> Aquestes cookies ajuden a fer que el lloc web funcioni i ofereixi els serveis que el visitant del lloc web espera, com ara navegar per pàgines web o accedir a àrees segures del lloc web.
                    </li>
                    <li>
                    <strong>Cookies Específiques:</strong> Aquestes cookies permeten al lloc web recordar la següent informació: nom i número de telèfon.
                    </li>
                </ul>
                <h3>Com podeu desactivar les cookies?</h3>
                <p>
                    Podeu permetre, bloquejar o eliminar les cookies instal·lades al vostre ordinador mitjançant les opcions de configuració del navegador instal·lat al vostre ordinador.
                </p>
                <ul>
                    <li>
                    Per a Chrome, vegeu aquesta pàgina: <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647</a>
                    </li>
                    <li>
                    Per a Explorer, vegeu aquesta pàgina: <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                    </li>
                    <li>
                    Per a Firefox, vegeu aquesta pàgina: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
                    </li>
                    <li>
                    Per a Safari, vegeu aquesta pàgina: <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
                    </li>
                </ul>
                `
            }
        }
    }
}